import React from "react";
import { Link } from "react-router-dom";
import typewriter from "../../images/typewriter.png";
import astronaut from "../../images/astronaut.png";

export default function Result({ result, type }) {
  let imageUrl = "";
  if (result.lede_image) {
    imageUrl = result.lede_image.url;
  } else if (result.type === "person") {
    imageUrl = astronaut;
  } else {
    imageUrl = typewriter;
  }
  const url = "/" + (type === "post" ? "article/" : type === "page" ? "" : type + "/") + result.slug;
  return (
    <Link key={result.id} to={url} className="result">
      <div className={"result-information"}>
        <h2 className="result-name" dangerouslySetInnerHTML={{ __html: result.title }} />
        {type === "work" && result.industry && (
          <span className="result-meta-name" aria-hidden="true">
            {result.industry}
          </span>
        )}
      </div>
      <div className={"result-featured-img"} style={{ background: `url('${imageUrl}') no-repeat center/contain` }} aria-hidden></div>
    </Link>
  );
}
