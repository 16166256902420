import React, { Component } from "react";

class Work extends Component {
  render() {
    const { post } = this.props;

    return (
      <div className={"work"}>
        <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
        <dl className="work-meta-list">
          {post.acf.client_name && (
            <>
              <dt className="h3 title">Client</dt>
              <dd>{post.acf.client_name}</dd>
            </>
          )}
          {post.acf.year && (
            <>
              <dt className="h3 title">Year</dt>
              <dd>{post.acf.year}</dd>
            </>
          )}
          {post.acf.collaborators && (
            <>
              <dt className="h3 title">Collaborators</dt>
              <dd>
                {post.acf.collaborators.map((collaborator, index) => (
                  <div key={index}>{collaborator.post_title}</div>
                ))}
              </dd>
            </>
          )}
          {post.acf.industry && (
            <>
              <dt className="h3 title">Industry</dt>
              <dd>{post.acf.industry}</dd>
            </>
          )}
          {post.acf.type_of_work && (
            <>
              <dt className="h3 title">Type of Work</dt>
              <dd>{post.acf.type_of_work}</dd>
            </>
          )}
        </dl>
        <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
      </div>
    );
  }
}

export default Work;
