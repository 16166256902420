import React from "react";
import Work from "./Work.js";
import Person from "./Person.js";
import Post from "./Post.js";
import Page from "./Page.js";
import Snippet from "./Snippet.js";
import PageTheatreManager from "./PageTheatreManager.js";
import PageTheatreManagerV2 from "./PageTheatreManagerV2.js";
import PageAccess from "./PageAccess.js";
import PageElevate from "./PageElevate.js";

const postTypes = {
  work: <Work />,
  person: <Person />,
  post: <Post />,
  page: <Page />,
  snippet: <Snippet />,
  "theatre-manager-old": <PageTheatreManager />,
  "theatre-manager": <PageTheatreManagerV2 />,
  // access: <PageAccess />,
  elevate: <PageElevate />,
};

export default postTypes;
