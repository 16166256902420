import React from "react";
import { useState, useEffect } from "react";
import Store from "../../Store";
import { Link } from "react-router-dom";

export default function ResultsPane({ type }) {
  const [defaultContent, setDefaultContent] = useState(null);

  useEffect(() => {
    Store.getPostBySlug("snippets", "introduction").then((res) => {
      setDefaultContent({ style: "", ...res[0] });
    });
  }, []);

  if (!defaultContent) return null;

  return (
    <div className="results intro">
      <div>
        <div className={`result${defaultContent.style}`} dangerouslySetInnerHTML={{ __html: defaultContent.acf.snippet_content }} />
      </div>
    </div>
  );
}
