import React, { Component } from "react";
import classNames from "classnames";

class Snippet extends Component {
  render() {
    const { selected, value, result, isTouchDevice, showingContent, resultRef } = this.props;
    const effect = this.props.result.effect;
    const valueLowerCase = value.toLowerCase();

    const activateCss = result.css && effect && (result.title.toLowerCase() === valueLowerCase || selected);

    // hack to disable inputs, buttons, and links in the snippet content
    let snippetContent = result.snippet_content;
    if (showingContent) {
      snippetContent = snippetContent.replace(/<input/g, "<input disabled");
      snippetContent = snippetContent.replace(/<button/g, "<button disabled");
      snippetContent = snippetContent.replace(/<a/g, '<a tabindex="-1"');
    }

    const isContactUs = result.title.toLowerCase() === "contact us";
    const TitleComponent = isContactUs ? "h1" : showingContent ? "p" : "h2";

    const childComponent = (
      <div
        className={classNames("result snippet", { selected: selected, isTouch: isTouchDevice() })}
        key={result.id}
        ref={resultRef}
        onClick={(event) => this.props.onClick(event, result)}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {result.title && <TitleComponent className="h1" dangerouslySetInnerHTML={{ __html: result.title }} />}

        {result.snippet_content && <div dangerouslySetInnerHTML={{ __html: snippetContent }} />}
        {activateCss && <div dangerouslySetInnerHTML={{ __html: result.css }} />}
      </div>
    );
    return childComponent;
  }
}

export default Snippet;
