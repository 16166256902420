window.dnsStore = window.dnsStore || { searchIndex: null, posts: {} };

const baseURL = "/wp-json/wp/v2";
const searchBaseURL = "/wp-admin/admin-ajax.php?action=search_index";

class Store {
  static getSearchIndex() {
    if (window.dnsStore["searchIndex"]) {
      return Promise.resolve(window.dnsStore["searchIndex"]);
    } else {
      return fetchData(searchBaseURL).then((res) => {
        if (res) {
          window.dnsStore["searchIndex"] = res;
        }
        return res;
      });
    }
  }

  static getPost(post_id) {
    if (window.dnsStore["posts"][post_id]) {
      return Promise.resolve(window.dnsStore["posts"][post_id]);
    } else {
      return fetchData(baseURL + "/posts/" + post_id).then((res) => {
        if (res) {
          window.dnsStore["posts"][post_id] = res;
        }
        return res;
      });
    }
  }

  static getPostBySlug(post_type, post_slug) {
    if (window.dnsStore["posts"][post_type] === undefined) {
      window.dnsStore["posts"][post_type] = {};
    }

    if (window.dnsStore["posts"][post_type][post_slug] !== undefined) {
      return Promise.resolve(window.dnsStore["posts"][post_type][post_slug]);
    } else {
      return fetchData(`${baseURL}/${post_type}?slug=${post_slug}`).then((res) => {
        if (res) {
          window.dnsStore["posts"][post_type][post_slug] = res;
        }
        return res;
      });
    }
  }
}

export default Store;

function fetchData(url) {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");

  return fetch(url, { headers: headers })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
}
