import React, { Component } from "react";

class PageAccess extends Component {
  render() {
    const post = this.props.post;

    return (
      <div className="access">
        <div className="access-hero-illustration">
          <h1 className="header-extra-large">All In: Accessibility for Arts & Culture Websites</h1>
        </div>
        <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
      </div>
    );
  }
}

export default PageAccess;
