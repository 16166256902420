import React, { Component } from "react";

class LoadingSpinner extends Component {
  render() {
    return (
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    );
  }
}

export default LoadingSpinner;
