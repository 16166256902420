import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import Store from "../../Store";

export default function Acknowledgement({ showAcknowledgement, handleClose }) {
  const [defaultContent, setDefaultContent] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const closeBtn = useRef(null);

  useEffect(() => {
    Store.getPostBySlug("snippets", "acknowledgement").then((res) => {
      setDefaultContent(res[0]);
    });
  }, []);

  useEffect(() => {
    if (showAcknowledgement) {
      setIsFinished(true);
      gsap.to(".slideInPanel--acknowledgement", {
        visibility: "visible",
        autoAlpha: 1,
        transform: "translateY(0)",
        pointerEvents: "auto",
        duration: 0.3,
        ease: "power2.inOut",
        onComplete: () => {
          closeBtn.current.focus();
        },
      });
    } else {
      gsap.to(".slideInPanel--acknowledgement", {
        autoAlpha: 0,
        pointerEvents: "none",
        transform: "translateY(-100%)",
        duration: 0.3,
        ease: "power2.inOut",
        onComplete: () => {
          setIsFinished(false);
        },
      });
    }
  }, [showAcknowledgement]);

  useEffect(() => {
    if (isFinished) {
      // need to do something here, not sure if ref works for here
      const focusableElements = document.querySelectorAll(".slideInPanel--acknowledgement a, .slideInPanel--acknowledgement button");
      const firstFocusableElement = closeBtn.current;
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      const trapFocus = (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          if (document.activeElement === lastFocusableElement) {
            e.preventDefault();
            firstFocusableElement.focus();
          }
        } else if (e.shiftKey && e.key === "Tab") {
          if (document.activeElement === firstFocusableElement) {
            e.preventDefault();
            lastFocusableElement.focus();
          }
        }
        if (e.key === "Escape") {
          handleClose();
        }
      };

      document.addEventListener("keydown", trapFocus);

      return () => {
        document.removeEventListener("keydown", trapFocus);
      };
    }
  }, [isFinished]);

  if (!defaultContent) return null;

  return (
    <dialog className="content slideInPanel slideInPanel--acknowledgement" open={isFinished} aria-hidden={!showAcknowledgement}>
      <div className="slideInPanel-header">
        <button className="close-items" aria-label="Close Land Acknowledgement" onClick={handleClose} ref={closeBtn}>
          <span className="close-text" aria-hidden>
            <span aria-hidden>&times;</span> Close
          </span>
        </button>
      </div>
      <div>
        <h1>{defaultContent.title.rendered}</h1>
        <div className={`result`} dangerouslySetInnerHTML={{ __html: defaultContent.content.rendered }} />
      </div>
    </dialog>
  );
}
