import React, { Component } from "react";

class Person extends Component {
  render() {
    const post = this.props.post;

    return (
      <div className="person post-content">
        <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
        {post.acf.photo && <img className="profile-pic" src={post.acf.photo.url} alt={post.acf.photo.alt} />}
        <div>{post.acf.short_bio}</div>
      </div>
    );
  }
}

export default Person;
