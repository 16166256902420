import React, { useState, useEffect, useRef, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useNavigate, useLocation } from "react-router-dom";
import Results from "./components/panes/Results.js";
import Content from "./components/panes/Content.js";
import Home from "./components/panes/Home.js";
import Sidebar from "./components/Sidebar.js";
import Search from "./components/panes/Search";
import Acknowledgement from "./components/panes/Acknowledgement";
import LoadingSpinner from "./components/LoadingSpinner";

import classNames from "classnames";

var isTouchDevice = require("is-touch-device");

const SCROLL_OFFSET = 100;

const queryMatches = [
  {
    device: "mobile",
    matchMedia: window.matchMedia("screen and (max-width: 600px)"),
  },
  {
    device: "desktop",
    matchMedia: window.matchMedia("screen and (min-width: 601px)"),
  },
];

function AppContent() {
  const [state, setState] = useState({
    isLoggedIn: false,
    hoveredPostID: null,
    selectedPostID: null,
    searchTerm: "",
    results: [],
    queryMatch: "",
    closingRightPane: false,
    defaultContent: null,
    searchLoading: true,
    contentLoaded: false,
    showingContent: false,
    showSearch: false,
    showAcknowledgement: false,
  });

  const listRef = useRef(null);
  const closeButtonRef = useRef(null);
  const firstResultRef = useRef(null);
  const secondResultRef = useRef(null);
  const rightPaneRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = (mediaQuery) => {
      if (mediaQuery.matchMedia.matches) {
        setState((prevState) => ({ ...prevState, queryMatch: mediaQuery }));
      }
    };

    for (let query of queryMatches) {
      query.matchMedia.addListener(() => handleResize(query));

      if (query.matchMedia.matches) {
        setState((prevState) => ({ ...prevState, queryMatch: query }));
        handleResize(query);
      }
    }

    getLoggedInStatus();
    handlePathChange(window.location.pathname, window.location.hash);

    return () => {
      for (let query of queryMatches) {
        query.matchMedia.removeListener(() => handleResize(query));
      }
    };
  }, []);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      let currentScrollPos = window.pageYOffset;
      if (true || state.queryMatch.device === "mobile") {
        if (currentScrollPos > prevScrollPos && currentScrollPos > 0) {
          // hide menu
          document.getElementsByClassName("sidebar")[0].classList.add("hide");
          prevScrollPos = currentScrollPos;
        } else if (currentScrollPos < prevScrollPos - SCROLL_OFFSET || currentScrollPos < SCROLL_OFFSET) {
          // show menu
          document.getElementsByClassName("sidebar")[0].classList.remove("hide");
          prevScrollPos = currentScrollPos;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [state.queryMatch]);

  useEffect(() => {
    if (state.searchTerm !== "" && state.showSearch) {
      handleCloseSearch();
    }
    if (state.showAcknowledgement) {
      handleCloseAcknowledgement();
    }
  }, [location]);

  const handlePathChange = (path, hash) => {
    console.log("App: handlePathChange:", path, hash);
    const isContentPath = hash.length === 0 && path !== "/";
    // const isContentPath =
    //   path.indexOf("/work/") > -1 ||
    //   path.indexOf("/person/") > -1 ||
    //   path.indexOf("/article/") > -1 ||
    //   path.indexOf("/info/") > -1
    if (isContentPath) {
      setState((prevState) => ({
        ...prevState,
        showingContent: isContentPath,
        selectedPostID: isContentPath ? prevState.selectedPostID : null,
      }));
    }
  };

  // TODO: showingContent is always false
  const closeContentPane = () => {
    console.log("App: closeContentPane");
    // let animationDelay = 450;

    // if (this.state.showingContent) {
    //   this.setState({ closingRightPane: true });

    //   setTimeout(() => {
    //     this.setState(
    //       {
    //         showingContent: false,
    //         closingRightPane: false,
    //         contentLoaded: false,
    //       },
    //       () => {
    //         if (!this.state.showingContent) {
    //           history.push("/" + (this.state.searchTerm.length > 0 ? `#${this.state.searchTerm}` : ""));

    //           if (document.querySelector(".leftPane .selected")) {
    //             document.querySelector(".leftPane .selected").focus();
    //           }
    //         }
    //       }
    //     );
    //   }, animationDelay);
    // }
  };

  const handleSelectionChange = useCallback(
    (e, value) => {
      e.stopPropagation();

      if (value.type === "post") {
        navigate(`/article/${value.slug}`);
      } else if (value.type === "page") {
        navigate(`/${value.slug}`);
      } else if (value.type !== "snippet") {
        navigate(`/${value.type}/${value.slug}`);
      }

      setState((prevState) => ({
        ...prevState,
        selectedPostID: value.id,
        contentLoaded: false,
        searchTerm: value.type === "snippet" && value.effect ? value.title.toLowerCase() : prevState.searchTerm,
      }));
    },
    [navigate]
  );

  const handleHoveredPostChange = (post) => {
    setState((prevState) => ({ ...prevState, hoveredPostID: post ? post.id : null }));
  };

  const handleClear = useCallback(() => {
    console.log("App: handleClear");
    setState((prevState) => ({
      ...prevState,
      searchTerm: "",
      results: [],
      selectedPostID: null,
      contentLoaded: false,
    }));
    closeContentPane();
    // clear hash
    navigate("/");
  }, [navigate]);

  const handleClearContent = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: "",
      results: [],
      selectedPostID: null,
      contentLoaded: false,
    }));
    // clear hash
    navigate("/");
  }, [navigate]);

  const getLoggedInStatus = () => {
    fetch("/wp-admin/admin-ajax.php?action=is_logged_in", {
      credentials: "include",
    }) // { credentials: 'same-origin' } is more secure once we're in production.
      .then((res) => res.json())
      .then((res) => {
        setState((prevState) => ({ ...prevState, isLoggedIn: res.data }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, isLoggedIn: false }));
      });
  };

  const handleKeywordChange = (keyword) => {
    setState((prevState) => ({
      ...prevState,
      searchLoading: keyword.length >= 3,
      searchTerm: keyword,
      showSearch: keyword.length >= 3,
    }));
  };

  // Close acknowledgement when showAcknowledgement && showSearch
  useEffect(() => {
    if (state.showAcknowledgement && state.showSearch) {
      handleCloseAcknowledgement();
    }
  }, [state.showAcknowledgement, state.showSearch, state.searchTerm]);

  const inputFocusRef = useRef(null);
  const handleSearchSubmit = (isKeyboard) => {
    // search and showSearch
    setState((prevState) => ({ ...prevState, showSearch: true }));

    if (isKeyboard) {
      inputFocusRef.current.focus();
    }
  };

  const sidebarRef = useRef(null);

  const handleCloseSearch = () => {
    setState((prevState) => ({
      ...prevState,
      showSearch: false,
      searchTerm: "",
      results: [],
      searchLoading: false,
    }));
    sidebarRef.current.focusSearchField();
  };

  const handleToggleAcknowledgement = () => {
    setState((prevState) => ({ ...prevState, showAcknowledgement: !prevState.showAcknowledgement }));

    if (!state.showAcknowledgement) {
      sidebarRef.current.focusAcknowledgeBtn();
    }
  };
  const handleCloseAcknowledgement = () => {
    setState((prevState) => ({ ...prevState, showAcknowledgement: false }));
    sidebarRef.current.focusAcknowledgeBtn();
  };

  // Permalink handler
  const handleContentLoad = (post) => {
    setState((prevState) => ({ ...prevState, contentLoaded: true }));
  };

  // make sure scroll to top when location changes
  useEffect(() => {
    rightPaneRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  const { showingContent, isLoggedIn, closingRightPane, searchTerm, contentLoaded, showSearch, showAcknowledgement } = state;
  const device = state.queryMatch.device;

  const contentProps = {
    isLoggedIn: isLoggedIn,
    onClick: closeContentPane,
    device: device,
    onContentLoad: handleContentLoad,
    closeButtonRef: closeButtonRef,
    ariaHidden: showAcknowledgement || showSearch,
  };

  return (
    <div
      className={classNames("app", {
        "showing-content": showingContent,
        "showing-nav": !showingContent,
        "closing-right-pane": closingRightPane,
        "has-results": state.results.length > 0,
        isTouchDevice: isTouchDevice(),
        isPointerDevice: !isTouchDevice(),
      })}
    >
      <a className="skip-link" href="#main">
        Skip to content
      </a>
      <div className="sidebar" onClick={closeContentPane}>
        <Sidebar
          device={device}
          showAcknowledgement={showAcknowledgement}
          searchValue={state.searchTerm}
          handleClear={handleClear}
          handleKeywordChange={handleKeywordChange}
          handleSearchSubmit={handleSearchSubmit}
          handleToggleAcknowledgement={handleToggleAcknowledgement}
          ref={sidebarRef}
        />
      </div>
      <main className="rightPane" ref={rightPaneRef} id="main">
        <Search showSearch={showSearch} keyword={state.searchTerm} handleClose={handleCloseSearch} ref={inputFocusRef} />
        <Acknowledgement showAcknowledgement={showAcknowledgement} handleClose={handleCloseAcknowledgement} />
        <Routes>
          <Route path="/" element={<Home {...contentProps} />} />
          <Route path="/work" element={<Results type={"work"} results={state.results} {...contentProps} />} />
          <Route path="/work/:slug" element={<ContentWrapper type="work" {...contentProps} />} />
          <Route path="/person/:slug" element={<ContentWrapper type="person" {...contentProps} />} />
          <Route path="/article" element={<Results type={"post"} results={state.results} {...contentProps} />} />
          <Route path="/article/:slug" element={<ContentWrapper type="post" {...contentProps} />} />
          <Route path="/page/:slug" element={<Navigate to={"/" + useParams().slug} replace />} />
          <Route path="/:slug" element={<ContentWrapper type="page" {...contentProps} />} />
        </Routes>
      </main>
      {isLoggedIn && (
        <div className="admin-links">
          <a className="dashboard admin-link" href="/wp-admin">
            Admin Dashboard
          </a>
        </div>
      )}
    </div>
  );
}

function ContentWrapper({ type, ...props }) {
  const { slug } = useParams();
  return <Content slug={slug} type={type} {...props} />;
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
